* {
  font-family: 'Lato', sans-serif !important;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  background-color: var(--bg-dashboard) !important;
  /* overflow: auto !important; */
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}
:root {
  --primary-color-1: #2c659b;
  --primary-color-2: #0d467d;
  --primary-color-3: #fbb812;
  --secondary-color-1: #d09037;
  --secondary-color-2: #58585a;
  --secondary-color-3: #b1ada2;
  --secondary-color-4: #ece5d6;
  --neutral-color-1: #222222;
  --neutral-color-2: #bbbbbc;
  --bg-dashboard: #f0ede7;
  --bg-green: #62d9a0;
}
.input_error_text {
  color: #ce6262;
  font-size: 10px;
  margin-left: 8px;
}
.font_extraLight {
  font-weight: 300 !important;
  font-family: 'Lato' !important;
  font-style: normal !important;
}
.font_light {
  color: var(--secondary-color-2) !important;
  font-weight: 400 !important;
}
.font_primary1 {
  color: var(--primary-color-1) !important;
}
.font_secondary2 {
  color: var(--secondary-color-2) !important;
}
.font_secondary3 {
  color: var(--secondary-color-3) !important;
}
.font_white {
  color: rgba(255, 255, 255, 1) !important;
}
.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 14px !important;
}
.font18 {
  font-size: 18px !important;
}
.font16 {
  font-size: 16px !important;
}
.font22 {
  font-size: 22px !important;
}
.font24 {
  font-size: 24px !important;
}
.font28 {
  font-size: 28px !important;
}
.font32 {
  font-size: 32px !important;
}
.font400 {
  font-weight: 400 !important;
}
.font600 {
  font-weight: 600 !important;
}
.font700 {
  font-weight: 700 !important;
}
.txt_white {
  color: white !important;
}
.txt_link {
  text-decoration: none !important;
  color: var(--primary-color-1) !important;
}
.txt_link:hover {
  text-decoration: underline !important;
  color: var(--primary-color-2) !important;
}
.transition {
  transition: all 0.3s ease-in-out !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb16 {
  margin-bottom: 16px !important;
}
.mt13 {
  margin-top: 13px !important;
}
.mt19 {
  margin-top: 19px !important;
}
.mt24 {
  margin-top: 24px !important;
}

/* =================== Scroll Bar Design ==================== */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 1px 1px 15px var(--secondary-color-4) !important;
  border-radius: 10px;
  overflow: hidden !important;
  background-color: #e3e0d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1ada2 !important;
  border-radius: 10px;
}
/* ===================== Button effect ===================== */
.btn_common {
  background-color: var(--primary-color-1) !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  padding: 10px !important;
  width: 100% !important;
}
.inputMargin {
  margin-top: 16px !important;
}
.btn_common:hover {
  background-color: var(--primary-color-2) !important;
}

.no_margintop {
  margin-top: 0px;
}

.btn.dialog-action-button {
  background-color: white;
  border: none;
  color: var(--primary-color-1);
  width: 50px;
}
.btn.action-right-button {
  margin-left: 5px;
}

.btn.dialog-action-button:focus,
.btn.dialog-action-button:active,
.btn.dialog-action-button:hover {
  box-shadow: none !important;
  outline: 0;
  color: white;
  background-color: var(--primary-color-1);
}

/* ===================== Auth Layout ===================== */
.auth_box {
  height: 100vh !important;
}
/* .form_box{
  padding-top: 130px !important;
} */
.form_alignments {
  width: 360px !important;
}
.logo {
  width: 180px !important;
}
.main_form {
  padding: 30px 0 0 0 !important;
}

/* ===================== Form elements ===================== */
.form_title {
  font-size: 28px !important;
  padding: 60px 0 0 0 !important;
}
.form_subtitle {
  font-size: 15px !important;
  padding: 12px 0 0 0 !important;
}
.form_label {
  margin-bottom: 2px !important;
  color: var(--secondary-color-2);
}
.form-control:focus,
.form_select:focus {
  box-shadow: none !important;
  border: 1px solid #2c659b !important;
}
.form-check-input {
  border: 1px solid var(--secondary-color-3) !important;
}
.form-check-input:checked {
  background-color: var(--primary-color-1) !important;
  box-shadow: none !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  border: 1px solid var(--secondary-color-3) !important;
}
.form-switch input,
.form-switch input:focus {
  height: 24px !important;
  width: 40px !important;
  background-color: #e2e6ea !important;
  border: none !important;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e') !important;
}
.form-switch input:checked {
  background-color: var(--bg-green) !important;
}

/* ===================== Carousel Settings ===================== */
.carousel-inner {
  height: 100% !important;
}
.carousel-control-prev {
  display: none !important;
}
.carousel-control-next {
  display: none !important;
}
.carousel-indicators {
  top: 15px !important;
  margin-right: 15px !important;
  margin-left: 0 !important;
  left: 80% !important;
  /* display: flex !important; */
  display: none !important;
  justify-content: end !important;
}
.carousel-indicators [data-bs-target] {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  margin: 5px !important;
}
.carousel-indicators .active {
  background-color: var(--primary-color-3) !important;
  border-color: var(--primary-color-3) !important;
}
.carousel-caption {
  right: 0 !important;
  left: 65px !important;
  bottom: 122px !important;
  text-align: left !important;
  width: 500px !important;
  background-color: rgba(44, 101, 155, 0.37);
  border: 1px solid rgba(44, 101, 155, 0.5) !important;
  backdrop-filter: blur(12px);
  border-radius: 8px !important;
  padding: 32px !important;
}

/* ===================== Popover Settings ===================== */
.popover {
  max-width: 200px !important;
  min-width: 200px !important;
}
.popover-header {
  background-color: #ffffff !important;
  padding: 10px !important;
}
.popover-body {
  padding: 10px !important;
}
.btn_popover {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: var(--secondary-color-2) !important;
  margin-bottom: 3px !important;
}
.btn_popover:hover {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

/* ===================== Main Layout (sidebar) ===================== */
.sidebar {
  top: 0;
  bottom: 0;
  min-width: 200px !important;
  max-width: 200px !important;
  min-height: 100vh !important;
  background: linear-gradient(
    180deg,
    #ffffff 22.51%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  padding: 22px 0px !important;
  border: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sidebar.active {
  margin-left: -200px !important;
}
.sidebar-header {
  padding: 0px 23px !important;
  width: 100% !important;
}
.sidebar-body {
  display: flex !important;
  justify-content: center !important;
  padding: 0px 23px !important;
  width: 100% !important;
  margin-top: 48px !important;
}
.offcanvas-backdrop {
  background-color: transparent !important;
}
.menu_box {
  width: 142px !important;
}
.menu_item {
  display: block !important;
  padding: 16px !important;
  text-decoration: none !important;
  border-radius: 16px !important;
  margin-bottom: 11px !important;
}
.menu_box .active {
  background-color: rgba(236, 229, 214, 0.3) !important;
}
.menu_icon {
  margin-right: 10px !important;
  width: 22px;
  height: 22px;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.no-data-message {
  font-size: 20px;
  color: #2c659b;
}
/* ===================== Main Layout ===================== */
.header {
  padding: 0px 24px;
}

.main_layout {
  width: 100%;
  margin: 24px 0px 24px 0px !important;
  overflow: hidden;
}
.btn_new {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin-left: 20px !important;
  padding: 0 !important;
}
.btn_plus {
  height: 32px !important;
  width: 32px !important;
  border-radius: 50% !important;
  background-color: var(--primary-color-1) !important;
}
.searchBar {
  border: none !important;
  border-bottom: 2px solid var(--secondary-color-3) !important;
}
.searchCommon {
  padding-left: 0 !important;
  border: none !important;
  background-color: transparent !important;
}
.searchCommon:focus {
  box-shadow: none !important;
  border: none !important;
}
.btn_search {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  height: 35px !important;
  width: 35px !important;
  background-image: url('../images/icon/searchIcon.svg');
  background-repeat: no-repeat;
  background-position: center !important;
}
.profile_img {
  margin-left: 42px !important;
  padding: 0 !important;
  height: 55px !important;
  width: 55px !important;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: end;
}
.btn_caretDown {
  padding: 0 !important;
  margin: 0 0px 0px 10px !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.btn_menu {
  padding: 5px 8px !important;
  color: var(--secondary-color-3) !important;
  background-color: transparent !important;
  border-radius: 2px !important;
  border: 1px solid transparent !important;
}
.btn_menu:focus {
  background-color: rgba(177, 173, 162, 0.1) !important;
  border: 1px solid rgba(177, 173, 162, 1) !important;
  box-shadow: 1px 1px 4px rgba(177, 173, 162, 0.4) !important;
}
.page_head {
  display: block !important;
}

/* ===================== Dashboard Page ===================== */
.dashboard-container {
  padding: 0px 24px;
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 0px 24px;
  padding-bottom: 10px;
}
.progress_card {
  height: 200px !important;
  background-color: #fff !important;
  border-radius: 16px !important;
  box-shadow: 0px 4px 4px rgba(177, 173, 162, 0.1),
    0px 20px 28px rgba(177, 173, 162, 0.3) !important;
  padding: 15px 20px !important;
  border: 1px solid var(--secondary-color-4);
}
.progress_chart {
  margin-top: 35px !important;
}
.progress_circle {
  width: 90px !important;
  height: 90px !important;
}
.dots {
  height: 12px !important;
  width: 12px !important;
  border-radius: 50% !important;
  border: 1px solid rgba(177, 173, 162, 0.1) !important;
  margin-right: 9px !important;
}
.eng_img {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

/* .dashboard-progress-card-container {
} */

/* ===================== User Page ======================= */
.btn.addnew_button {
  color: #2c659b;
  background-color: transparent;
  border: none;
  margin-left: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  border-radius: 30px;
}
.btn.addnew_button:hover {
  background-color: transparent;
  color: #2c659b;
}

.btn.addnew_button:focus {
  background-color: transparent;
}

.btn.addnew_button:focus,
.btn.addnew_button:active,
.btn.addnew_button:hover {
  box-shadow: none !important;
  outline: 0;
  color: #2c659b;
}

.addnew_icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* ===================== AddNew Dialog ======================= */

.dialog_back_effect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #030303;
  opacity: 0.5;
  z-index: 3;
}

.reset_dialog_back_effect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  backdrop-filter: blur(20px) saturate(120%) contrast(50%);
}

.add_dialog_container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  background-color: white;
  z-index: 2;
}

.add_dialog_inner_container {
  width: 100%;
  height: 100%;
  padding: 25px;
}

.add_dialog_close_icon {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.reset_dialog_close_icon {
  position: absolute;
  right: -30px;
  top: 0px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.dialog_title {
  color: var(--secondary-color-2);
  font-size: 25px;
}

.reset-dialog-title {
  color: var(--secondary-color-2);
  font-size: 25px;
  font-weight: 400;
  font-size: 25px;
}

.reset-dialog-title2 {
  font-size: 14px;
}

.job_dialog_close_icon {
  position: absolute;
  right: -30px;
  top: 0px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.job_dialog_header_container {
  padding: 15px 0px !important;
  border-bottom: 1px solid var(--secondary-color-4) !important;
}

.user_profile {
  height: 32px !important;
  width: 32px !important;
}

.addnew_form {
  padding: 15px 0 20px 0 !important;
  overflow: auto;
  height: 100%;
}

.form-control.form_input,
.form_select {
  border: 1px solid var(--secondary-color-4);
  padding: 0.48rem 0.75rem !important;
  border-radius: 8px !important;
  height: 46px !important;
  color: #9b9b9c;
  width: 100%;
}
.form-control.form_input:focus {
  border: 1px solid #2c659b !important;
  color: var(--secondary-color-2);
}

.error-input.form-control.form_input,
.form_select {
  border: 1px solid #ce6262 !important;
}

.form-select.form_dropdown {
  border: 1px solid var(--secondary-color-4);
  padding: 0.48rem 0.75rem;
  border-radius: 8px;
  height: 46px;
  color: #9b9b9c;
}
.form-select.form_dropdown:focus {
  box-shadow: none !important;
  border: 1px solid #2c659b !important;
  color: var(--secondary-color-2);
}

.common-dialog-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-dialog-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job_dialog_container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.common-dialog-header {
  width: 100%;
  border-bottom: 1px solid var(--secondary-color-4);
}

.common-dialog-inner-container {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.reset-dialog-inner-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 60px 50px;
  margin-top: 80px;
  position: relative;
  align-items: center;
  width: 490px;
}
.job_dialog_inner_container {
  background-color: #ffffff;
  width: 975px !important;
  max-width: 100%;
  height: 780px !important;
  max-height: 98%;
  position: relative;
}

.job_dialog_main_body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.add_jobs_main_container {
  overflow: hidden;
  position: relative;
}

.common-dialog-message {
  width: 100%;
  padding: 40px 15px;
  font-size: 15px;
}

.common-dialog-title {
  color: var(--primary-color-1);
  font-size: 20px;
}

.action-container {
  width: 100%;
  display: flex;
  justify-content: end;
}

.add_jobs_main_container div div .job_title_input,
.add_jobs_main_container div div .job_title_input:focus {
  border: none !important;
  padding: 0 !important;
}

/* .due_datePicker::placeholder {
  color: var(--secondary-color-3) !important;
  font-weight: 400 !important;
} */

.react-datepicker-wrapper {
  width: 22px !important;
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  left: 0 !important;
}

.due_datePicker,
.due_datePicker:focus-visible {
  color: var(--secondary-color-2) !important;
  border: none !important;
  font-weight: 400 !important;
  outline: none !important;
  height: 22px !important;
  width: 22px !important;
  z-index: 1 !important;
  opacity: 0 !important;
}

.react-datepicker-popper {
  z-index: 3 !important;
}

.job_title_input::placeholder {
  color: var(--secondary-color-3) !important;
}
.form-select.job_dropDown,
.form-select.job_dropDown:focus {
  max-width: 150px !important;
  height: 30px !important;
  background-color: var(--secondary-color-4) !important;
  color: var(--secondary-color-2) !important;
  padding: 4px 7px !important;
  border: none !important;
}
.job_Details,
.job_Details:focus {
  padding: 4px 0 !important;
  border: none !important;
  resize: none !important;
  height: 120px !important;
  line-height: 22.4px !important;
}
.job_Details::placeholder {
  color: var(--secondary-color-2) !important;
  font-weight: 400 !important;
  opacity: 0.5 !important;
}
.add_job_file_drop_footer {
  position: absolute;
  bottom: 30px;
  left: 34px;
}
.file-drop-container {
  border: 2px;
  border-radius: 24px;
  width: 100%;
  height: 200px;
  border-style: dashed;
  border-color: #e2e6ea;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-drop-container-effect {
  background-color: #e9edf1;
  border-color: #2c659b;
}

.dropbox_label {
  margin: 15px 0px 8px 0px;
  color: var(--secondary-color-2);
}

.drop-text-container {
  font-size: 16px;
  font-weight: 400;
  color: #b1ada2;
  width: 170px;
  text-align: center;
}

.drop-browse-button {
  color: #d09037;
  cursor: pointer;
}
.uploaded-file-container {
  width: 100%;
}

.file-component-container {
  width: 100%;
  height: 50px;
  border: 1px solid var(--secondary-color-4);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
}

.file_name_container {
  display: flex;
  align-items: center;
  width: 90%;
  font-size: 12px;
  color: #9b9b9c;
}

.job_file_name_container {
  display: flex;
  align-items: center;
  width: 90%;
}

.uploaded-image {
  width: 30px;
  height: 30px;
}

.file_name {
  white-space: nowrap;
  max-width: 78%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.delete_file_icon {
  width: 20px;
  cursor: pointer;
}

.comment_section_left_sideColor {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 46%;
  width: 78px !important;
  background: linear-gradient(
    90deg,
    rgba(236, 229, 214, 0.3) 36.54%,
    rgba(236, 229, 214, 0) 104.49%
  );
  opacity: 0.35 !important;
}

/* .add_comment {
  position: absolute;
  bottom: 0;
  left: -9%;
  right: 0;
} */

.add_comment .add_comment_input {
  background: transparent;
}

.add_comment .add_comment_input,
.add_comment .add_comment_input:focus {
  border: none !important;
  border-left: 1px solid var(--secondary-color-4) !important;
  border-radius: 0 !important;
}

.add_comment .add_comment_input::placeholder {
  font-weight: 400;
  color: var(--secondary-color-3) !important;
}

.btn_comment {
  margin-right: 20px;
}

.btn_comment,
.btn_comment:focus,
.btn_comment:hover {
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  height: 24px !important;
  width: 24px !important;
}

.comment-card {
  padding: 26px;
  border: 1px solid var(--secondary-color-4);
  border-radius: 8px;
  background-color: white;
  /* margin-top: 20px; */
  color: var(--secondary-color-2);
  font-weight: 400;
  word-break: break-word;
}

/* ===================== Lots & Jobs ======================= */

.commentDeleteIcon {
  position: absolute;
  right: 4px;
  top: 7px;
}
.common_datacard {
  border-radius: 16px !important;
  border: 1px solid var(--secondary-color-4) !important;
  background-color: #fff !important;
}
.dataBox {
  padding: 36px 24px !important;
  box-shadow: 0px 4px 4px rgba(177, 173, 162, 0.1),
    0px 20px 28px rgba(177, 173, 162, 0.3) !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  min-height: 640px !important;
  max-height: 640px !important;
  overflow: auto !important;
}
.jobs_tab {
  padding: 8px 16px !important;
  border-radius: 16px !important;
  cursor: pointer !important;
}
.active_tab {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
/* .job-dataCard-main-box {
  min-width: 475px !important;
  padding-right: 20px !important;
} */
.jobs_dataBox {
  padding: 36px 24px !important;
  /* box-shadow: 0px 4px 4px rgba(177, 173, 162, 0.1),
    0px 20px 28px rgba(177, 173, 162, 0.3) !important; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  min-height: 830px !important;
  max-height: 830px !important;
  /* min-width: 475px !important; */
  overflow: auto !important;
}
.bg_sec3 {
  background-color: var(--secondary-color-3) !important;
}
.bg_prim1 {
  background-color: var(--primary-color-1) !important;
}
.bg_green {
  background-color: var(--bg-green) !important;
}
.dropdown_arrow {
  transform: rotate(180deg) !important;
}
.data_card {
  padding: 19px !important;
}
.progress.progress {
  width: 50px !important;
  height: 4px !important;
  border-radius: 1px;
}
.progress.lots-progress {
  width: 145px !important;
  height: 10px !important;
  border-radius: 1px;
}
.progress-bar {
  background-color: var(--bg-green) !important;
}
.job_dataCard {
  padding: 28px 24px !important;
}
/* .form-select.job-type-menu, .form-select.job-type-menu:focus{
  border: none !important;
  background-image: none !important;
  cursor: pointer !important;
} */
.nav-tabs {
  border-bottom: none !important;
}
.nav-tabs .nav-link {
  border: none !important;
  color: var(--secondary-color-3) !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  padding: 0 !important;
  margin-right: 35px !important;
}
.nav-tabs .nav-link.active {
  color: var(--secondary-color-1) !important;
  border-bottom: 1px solid var(--secondary-color-1) !important;
  background-color: transparent !important;
}
.btn_job {
  padding: 7px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.aml_box {
  background-color: var(--secondary-color-4) !important;
  color: var(--secondary-color-2) !important;
}
.priority_box {
  background-color: var(--secondary-color-1) !important;
  color: white !important;
  margin-left: 12px !important;
}
.MuiAvatar-circular {
  border: none !important;
  box-sizing: border-box !important;
  height: 42px !important;
  width: 42px !important;
  font-size: 14px !important;
}

/* ============ Data Table Design ============= */
.MuiToolbar-regular {
  min-height: 45px !important;
}
.dataTable_box {
  background-color: transparent !important;
  box-shadow: none !important;
  /* margin-top: 10px !important; */
}
table {
  border-collapse: separate !important;
  border-spacing: 0 8px !important;
}
thead tr th {
  font-weight: 700 !important;
  color: var(--secondary-color-2) !important;
  border: none !important;
  /* padding: 17px 0 !important; */
  font-size: 16px !important;
}
tbody tr td,
tbody tr th {
  color: var(--secondary-color-2) !important;
  padding: 24px 16px !important;
}
tbody tr {
  background-color: #ffffff !important;
  border-radius: 16px !important;
}
tr td,
tr th {
  border-bottom: none !important;
}
tr td:first-child,
tbody .coll_row th {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
tr td:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.status_view {
  padding: 5px 15px !important;
  border: 1px solid var(--secondary-color-1) !important;
  color: var(--secondary-color-1) !important;
  border-radius: 5px !important;
}
.btn_dataTable {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  width: 20px !important;
  /* z-index: 10 !important; */
}
.btn_dataTable:hover {
  background-color: transparent !important;
  border: none !important;
}
.table_checkbox .form-check-input {
  height: 24px !important;
  width: 24px !important;
  margin-left: 12px !important;
}
tr .table_commonDrop {
  padding: 5px 17px !important;
  border-radius: 8px !important;
}
tr .todo_drop {
  background: rgba(177, 173, 162, 0.4) !important;
}
tr .progress_drop {
  background: var(--primary-color-1) !important;
}
tr .complete_drop {
  background: var(--bg-green) !important;
}
.btn_tableDrop,
.btn_tableDrop:focus {
  box-shadow: none !important;
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
}

/* ========= Not Found =========== */
.not_found_container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not_found_body {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.font_notFound {
  font-size: 42px;
}
.font_404 {
  font-size: 110px;
}
.btn_notFound {
  width: 150px !important;
}
.notFound-page-logo {
  width: 200px;
  height: 100px;
}

/* ========================= Media Query ========================== */

@media screen and (max-width: 425px) {
  /* .form_box{
    padding-top: 20px !important;
  } */
  .form_alignments {
    width: 100% !important;
    padding: 0 10px !important;
  }
  .profile_img {
    height: 38px !important;
    width: 38px !important;
    margin-left: 10px !important;
  }
  .btn_popover {
    margin: 0 0 0 8px !important;
  }
  .page_head {
    display: none !important;
  }
  .progress_chart {
    margin-top: 22px !important;
  }
  .nav-tabs .nav-link {
    margin-right: 12px !important;
    margin-bottom: 10px !important;
  }
  /* .job_dialog_inner_container {
    width: 85% !important;
    height: 85% !important;
  } */
  .add_comment {
    left: 0 !important;
  }
  .font_notFound {
    font-size: 32px;
  }
  .font_404 {
    font-size: 85px;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .form_box {
    padding-top: 100px !important;
  }
  .profile_img {
    height: 40px !important;
    width: 40px !important;
    margin-left: 20px !important;
  }
  .progress_chart {
    margin-top: 25px !important;
  }
  /* .job_dialog_inner_container {
    width: 80% !important;
    height: 85% !important;
  } */
  .add_comment {
    left: 0 !important;
  }
  .font_404 {
    font-size: 110px;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .profile_img {
    height: 45px !important;
    width: 45px !important;
  }
  .progress_chart {
    margin-top: 29px !important;
  }
  .progress_circle {
    width: 70px !important;
    height: 70px !important;
  }
  .progress_chart {
    margin-top: 20px !important;
  }
  /* .job_dialog_inner_container {
    width: 75% !important;
    height: 85% !important;
  } */
  .add_comment {
    left: 0 !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1024px) {
  .profile_img {
    height: 45px !important;
    width: 45px !important;
  }
  .progress_chart {
    margin-top: 20px !important;
  }
  .progress_circle {
    width: 70px !important;
    height: 70px !important;
  }
  .font_info {
    font-size: 12px !important;
  }
  .card_title {
    font-size: 22px !important;
  }
  /* .job_dialog_inner_container {
    width: 75% !important;
    height: 85% !important;
  } */
}
@media screen and (min-width: 1025px) {
  .font_info {
    font-size: 12px !important;
  }
  .progress_chart {
    margin-top: 20px !important;
  }
  .card_title {
    font-size: 22px !important;
  }
}
@media screen and (min-width: 1440px) {
  .logo {
    width: 264px !important;
  }
  .font_info {
    font-size: 16px !important;
  }
  .card_title {
    font-size: 28px !important;
  }
}
